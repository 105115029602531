<template>
  <div class="pos">
    <b-row>
      <b-col cols="8">
        <b-row>
          <b-col cols="4" v-for="product in $store.state.products" :key="product.id" class="p-1">
            <b-card :img-src="$store.state.settings.platfromUrl + 'api/pos/productimage.php?id=' + product.id" img-top v-if="product.archived != true">
              <b-card-text>
                <h4>{{ product.name }}</h4>
              </b-card-text>
              <div v-if="product.options == false">
                <b-button block variant="success" v-on:click="addproducttocart(product.id)"><font-awesome-icon icon="cart-plus" class="fa-lg" /></b-button>
              </div>
              <div v-else>
                <b-button block variant="success" v-on:click="openproductmodal(product.id)"><font-awesome-icon icon="pen" class="fa-lg" /></b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4" class="p-1">
        <b-card header-tag="header" border-variant="primary" header-bg-variant="primary" no-body>
          <template #header>
            <h6 class="mb-0"><font-awesome-icon icon="shopping-cart" class="fa-lg" /> {{ $t('pos.cart.title') }}</h6>
          </template>
          <b-table striped :items="items" :fields="fields" class="cart-table">
            <template #cell(price)="data">
              {{ '€ ' + data.item.price.toFixed(2) }}
            </template>
            <template #cell(buttons)="data">
              <b-button block variant="danger" v-on:click="removeitemfromcart(data.index)"><font-awesome-icon icon="trash" class="fa-lg" /></b-button>
            </template>
          </b-table>
          <template #footer>
            <b-button block variant="success" :disabled="carttotal == 0" v-on:click="opencheckoutmodal()"><font-awesome-icon icon="check" class="fa-lg" /> {{ $t('pos.cart.order', {price : Math.abs(carttotal).toFixed(2)}) }}</b-button>
            <b-button block variant="danger" :disabled="carttotal == 0" v-on:click="clearcart()"><font-awesome-icon icon="times" class="fa-lg" /> {{ $t('pos.cart.cancel') }}</b-button>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="productmodal" :title="$t('pos.modal.title', {productname: productmodal.productname})" hide-footer size="xl">
      <div v-for="option in productmodal.options" :key="option.id">
        <b-form-checkbox v-model="option.enabled" :value="true" :unchecked-value="false" v-on:change="recalculatemodalprice()">{{ $t('pos.modal.option', {name: option.name, price: option.price}) }}</b-form-checkbox>
      </div>
      <br>
      <b-button block variant="success" v-on:click="addproductmodal()"><font-awesome-icon icon="check" class="fa-lg" /> {{ $t('pos.modal.add', {price : Math.abs(productmodal.price).toFixed(2)}) }}</b-button>
    </b-modal>
    <b-modal id="checkoutmodal" :title="$t('pos.checkout.title')" hide-footer size="xl">
      <h2 v-if="isUserSelected">
        <span v-if="selectedUser.type == 'student'">{{ $t('pos.checkout.student', { firstName: $store.state.students[selectedUser.id].firstName, lastName: $store.state.students[selectedUser.id].lastName } ) }}</span>
        <span v-if="selectedUser.type == 'teacher'">{{ $t('pos.checkout.teacher', { firstName: $store.state.teachers[selectedUser.id].firstName, lastName: $store.state.teachers[selectedUser.id].lastName } ) }}</span>
      </h2>
      <b-table striped :items="items" :fields="fieldscheckout" class="cart-table">
        <template #cell(price)="data">
          {{ '€ ' + data.item.price.toFixed(2) }}
        </template>
        <template #cell(options)="data">
          <span v-for="option in data.item.options" :key="option.id">
            <span v-if="$store.state.products[data.item.productid].options[option.id].enabled">
              <span v-if="!option.enabled">
                {{ '- ' + option.name }}<br>
              </span>
            </span>
            <span v-else>
              <span v-if="option.enabled">
                {{ '+ ' + option.name }}<br>
              </span>
            </span>
          </span>
        </template>
      </b-table>
      <br>
      <b-button block variant="success" v-on:click="checkout()"><font-awesome-icon icon="check" class="fa-lg" /> {{ $t('pos.checkout.checkout') }}</b-button>
    </b-modal>
  </div>
</template>

<script>
  import { postData } from '@/api'

  export default {
    name: 'Pos',
    data() {
      return {
        error: "",
        cart: [],
        carttotal: 0,
        fields: [{ key: 'product', label: this.$t('pos.cart.product')}, {key: 'buttons', label: ' '}],
        fieldscheckout: [{ key: 'product', label: this.$t('pos.cart.product')}, {key: 'options', label: this.$t('pos.cart.options')}, {key: 'price', label: this.$t('pos.cart.price')}],
        items: [],
        productmodal:{
          productname: '',
          productid: null,
          options: [],
          price: null
        },
        selectedUser: {},
        isUserSelected: false,
        studentsearch: ''
      }
    },
    methods: {
      clearcart: function() {
        this.cart = []
        this.carttotal = 0
        this.items = []
      },
      addproducttocart: function(itemid) {
        this.cart.push({productId: itemid, options: false})
        this.recalculatecart()
      },
      removeitemfromcart: function(itemid) {
        if (itemid > -1) {
          this.cart.splice(itemid, 1);
        }
        this.recalculatecart()
      },
      openproductmodal: function(itemid) {
        this.productmodal.productid = itemid
        this.productmodal.productname = this.$store.state.products[itemid].name
        this.productmodal.options = []
        // make array of options
        for (const property in this.$store.state.products[itemid].options) {
          this.productmodal.options.push({ ...this.$store.state.products[itemid].options[property], enabled: this.$store.state.products[itemid].options[property].defaultenabled})
        }
        this.recalculatemodalprice()
        this.$root.$emit('bv::show::modal', 'productmodal')
      },
      closeproductmodal: function() {
        this.$root.$emit('bv::hide::modal', 'productmodal')
      },
      addproductmodal: function() {
        this.cart.push({productId: this.productmodal.productid, options: this.productmodal.options, price: this.productmodal.price})
        this.recalculatecart()
        this.$root.$emit('bv::hide::modal', 'productmodal')
      },
      recalculatemodalprice: function() {
        this.productmodal.price = Math.abs(parseFloat(this.$store.state.products[this.productmodal.productid].price))
        for(var i=0; i<this.productmodal.options.length; i++) {
          if (this.productmodal.options[i].enabled) {
            this.productmodal.price += parseFloat(this.productmodal.options[i].price)
          }
        }
      },
      recalculatecart: function() {
        this.items = []
        var totalprice = 0
        for(var i=0; i<this.cart.length; i++){
          var productid = this.cart[i].productId;
          if (this.cart[i].options == false) {
            totalprice = totalprice + parseFloat(this.$store.state.products[productid].price)
            this.items.push({ price: Math.abs(parseFloat(this.$store.state.products[productid].price)), product: this.$store.state.products[productid].name })
          } else {
            totalprice = totalprice + this.cart[i].price
            this.items.push({ price: Math.abs(this.cart[i].price), product: this.$store.state.products[productid].name, options: this.cart[i].options, productid: productid })
          }
        }
        this.carttotal = totalprice
      },
      opencheckoutmodal: function() {
        this.recalculatecart()
        this.$root.$emit('bv::show::modal', 'checkoutmodal')
      },
      checkout: function() {
        this.recalculatecart()
        var senddata = {}
        senddata.user = this.selectedUser
        senddata.cart = this.cart
        postData('pos/addsale', senddata, (err) => {
          this.loading = false
          if (err) {
            this.error = err.toString()
            if(err.toString() == "Error: notenoughcredit") {
              this.$root.$bvToast.toast(this.$t('pos.webpos.errorcredit'), {
                title: this.$t('pos.webpos.errortitle'),
                autoHideDelay: 2500,
                variant: "danger"
              })
            } else {
              this.$root.$bvToast.toast(this.$t('pos.webpos.errorgeneric'), {
                title: this.$t('pos.webpos.errortitle'),
                autoHideDelay: 2500,
                variant: "danger"
              })
            }
          } else {
            this.$root.$emit('bv::hide::modal', 'checkoutmodal')
            this.$root.$bvToast.toast(this.$t('pos.webpos.success'), {
              title: this.$t('pos.webpos.successtitle'),
              autoHideDelay: 2500,
              variant: "success"
            })
            this.selectedUser = {}
            this.isUserSelected = false
            this.clearcart()
          }
        })
      },
      clearstudentselection() {
        this.selectedUser = {}
        this.isUserSelected = false
      },
      selectionchanged() {
        if (this.selectedUser != {}) {
          this.isUserSelected = true
        } else {
          this.isUserSelected = false
        }
      }
    }
  }
</script>

<style>
.card-img-top {
  max-height: 75px;
  object-fit: cover;
}
.cart-table {
  margin-bottom: 0;
}
</style>
