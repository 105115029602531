<template>
  <div class="cardinfo">
    <div v-if="($store.state.cardinfo.timestamp - Date.now()) < -100">
      <br>
      <div class="text-center text-secondary">
        <font-awesome-icon icon="spinner" class="fa-10x fa-spin" />
      </div>
      <h1 class="text-center">{{ $t('cardinfo.title') }}</h1>
    </div>
    <div v-else>
      <br>
      <br>
      <b-row>
        <b-col class="text-center">
          <h1 class="text-center">{{ $t('cardinfo.ownedby') }}</h1>
          <h4>{{ $store.state.cardinfo.firstName + ' ' + $store.state.cardinfo.lastName }}</h4>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CardInfo',
    methods: {
      enableTeachmode: function() {
        // run store teachmode action, this does all the work
        this.$store.commit('setteachmode')
      }
    }
  }
</script>

<style>

.userimage {
  width: 100%;
}

</style>
