import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    card: {
      code: null,
      timestamp: ''
    },
    cardinfo: {
      timestamp: '',
      firstName: '',
      lastName: '',
      userType: ''
    },
    token: {},
    settings: {},
    products: {},
    user: {},
    userhasordered: false,
    websocketstate: null,
    teachmode: false
  },
  mutations: {
    setcard (state, cardobject) {
      state.card.code = cardobject.code
      state.card.timestamp = cardobject.timestamp
      state.card.firstName = cardobject.firstName
      state.card.lastName = cardobject.lastName
      state.card.userType = cardobject.userType
    },
    setcardinfo (state, cardobject) {
      state.cardinfo.timestamp = cardobject.timestamp
      state.cardinfo.firstName = cardobject.firstName
      state.cardinfo.lastName = cardobject.lastName
      state.cardinfo.userType = cardobject.userType
    },
    setsettings (state, settingsobject) {
      state.settings = settingsobject
      state.settings.platfromUrl = 'https://' + settingsobject.platform + '.toekomstperspectief.be/'
    },
    setproducts (state, productsobject) {
      // clear products
      state.products = {}
      // loop over all new products
      for(var i=0; i<productsobject.length; i++){
        // copy the full object to the store with the array key being the id of the product
        state.products[productsobject[i].id] = { ...productsobject[i] }
        if (productsobject[i].options.length == 0) {
          // if the options are empty copy that over too.
          state.products[productsobject[i].id].options = false
        } else {
          // clear the options in the store
          state.products[productsobject[i].id].options = {}
          // loop over the options and them to the product in a array with the key being the id of the option
          for(var j=0; j<productsobject[i].options.length; j++) {
            state.products[productsobject[i].id].options[productsobject[i].options[j].id] = productsobject[i].options[j]
          }
        }
      }
    },
    settoken (state, token) {
      state.token = token
    },
    setuser (state, userobject) {
      state.user = userobject
    },
    setuserloggedout (state) {
      state.user = {}
    },
    setwebsocketstate (state, websocketstate) {
      state.websocketstate = websocketstate
    },
    setteachmode (state) {
      // set api key to teachmode
      state.settings.apikey = 'teachmode'
      // change platform info to teachmode one
      state.settings.platform = 'kioskteachmode'
      state.settings.platfromUrl = 'https://' + state.settings.platform + '.toekomstperspectief.be/'
      // set store flag so alert can be displayed on all pages
      state.teachmode = true
    },
    updateusercredit (state, credit) {
      state.user.credit = credit
    },
    setuserhasordered (state, value) {
      state.user.hasordered = value
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
  },
  strict: process.env.NODE_ENV !== 'production'
})
