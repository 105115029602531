import $ from "jquery";

export function postData(url, apikey, data, cb) {

  $.ajax({
      type: 'POST',
      url: url,
      headers: {'Apikey': apikey},
      data: JSON.stringify(data),
      contentType: "application/json",
      dataType: 'JSON',
      success: function(response){

        if (response.success == true) {

          cb(null, response.data)

        } else if ("error" in response) {

          cb(new Error(response.error))

        } else {

          cb(new Error('Comms error.'))

        }

      },
      error: function() {

        cb(new Error('Comms error.'))

      }

  });

}
