<template>
  <div class="error">
    <br>
    <p class="text-center">{{ $t('error.title') }}</p>
    <br>
    <b-list-group>
      <b-list-group-item>
        <span v-if="configuration == null"><font-awesome-icon icon="spinner" class="fa-lg fa-spin text-primary" /></span>
        <span v-else-if="configuration == false"><font-awesome-icon icon="times" class="fa-lg text-danger" /></span>
        <span v-else-if="configuration == true"><font-awesome-icon icon="check" class="fa-lg text-success" /></span>
        {{ $t('error.configuration') }}
      </b-list-group-item>
      <b-list-group-item>
        <span v-if="localservices == null"><font-awesome-icon icon="spinner" class="fa-lg fa-spin text-primary" /></span>
        <span v-else-if="localservices == false"><font-awesome-icon icon="times" class="fa-lg text-danger" /></span>
        <span v-else-if="localservices == true"><font-awesome-icon icon="check" class="fa-lg text-success" /></span>
        {{ $t('error.localservices') }}
      </b-list-group-item>
      <b-list-group-item>
        <span v-if="internet == null"><font-awesome-icon icon="spinner" class="fa-lg fa-spin text-primary" /></span>
        <span v-else-if="internet == false"><font-awesome-icon icon="times" class="fa-lg text-danger" /></span>
        <span v-else-if="internet == true"><font-awesome-icon icon="check" class="fa-lg text-success" /></span>
        {{ $t('error.internet') }}
      </b-list-group-item>
      <b-list-group-item>
        <span v-if="platform == null"><font-awesome-icon icon="spinner" class="fa-lg fa-spin text-primary" /></span>
        <span v-else-if="platform == false"><font-awesome-icon icon="times" class="fa-lg text-danger" /></span>
        <span v-else-if="platform == true"><font-awesome-icon icon="check" class="fa-lg text-success" /></span>
        {{ $t('error.platform') }}
      </b-list-group-item>
    </b-list-group>
    <br>
    <b-button block variant="primary" size="lg" href=".">{{ $t('error.reload') }}</b-button>
    <p class="text-center">{{ this.$store.state.settings.deviceuuid }}</p>
  </div>
</template>

<script>
  export default {
    name: 'Error',
    data() {
      return {
        configuration: null,
        localservices: null,
        internet: null,
        platform: null
      }
    },
    created () {
      this.checkconfiguration()
      this.checklocalservices()
      this.checkinternet()
      this.checkplatform()
    },
    methods: {
      checkconfiguration: function() {
        var tempstate = true
        if (this.$store.state.settings && Object.keys(this.$store.state.settings).length === 0 && Object.getPrototypeOf(this.$store.state.settings) === Object.prototype) {
          tempstate = false
        }
        if (this.$store.state.settings.apikey == '<apikey>' || this.$store.state.settings.apikey == '') {
          tempstate = false
        }
        if (this.$store.state.settings.deviceuuid == '<deviceuuid>' || this.$store.state.settings.deviceuuid == '') {
          tempstate = false
        }
        if (this.$store.state.settings.platform == '<platform>' || this.$store.state.settings.platform == '') {
          tempstate = false
        }
        if (tempstate == false) {
          this.configuration = false
        } else {
          this.configuration = true
        }
      },
      checklocalservices: function() {
        var tempstate = true
        if (this.$store.state.websocketstate == false) {
          tempstate = false
        }
        fetch(this.$store.state.settings.badgesystemhost + '/index.html').then().catch(
          tempstate = false
        );
        if (tempstate == false) {
          this.localservices = false
        } else {
          this.localservices = true
        }
      },
      checkinternet: function() {
        fetch('https://api.github.com/users/octocat/orgs').then(response => {
          console.log(response);
          this.internet = true
        }
        ).catch(
          this.internet = false
        );
      },
      checkplatform: function() {
        fetch(this.$store.state.settings.platfromUrl + 'api/kiosk/ping.php').then(response => {
          console.log(response);
          this.platform = true
        }
        ).catch(
          this.platform = false
        );
      }
    }
  }
</script>
