import Vue from 'vue'
import VueRouter from 'vue-router'
import Startup from '../views/Startup.vue'
import UserLogin from '../views/user/Login.vue'
import MainMenu from '../views/MainMenu.vue'
import InvalidBadge from '../views/InvalidBadge.vue'
import Lunch from '../views/Lunch.vue'
import Pos from '../views/Pos.vue'
import CardInfo from '../views/CardInfo.vue'
import SetPin from '../views/SetPin.vue'
import CheckPin from '../views/user/CheckPin.vue'
import Error from '../views/Error.vue'

import UserLogout from '../views/user/Logout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:token',
    name: 'Startup',
    component: Startup,
    props: true
  },
  {
    path: '/user/login/:token',
    name: 'Login',
    component: UserLogin,
    props: true
  },
  {
    path: '/user/logout/:token',
    name: 'Logout',
    component: UserLogout,
    props: true
  },
  {
    path: '/mainmenu/:token',
    name: 'MainMenu',
    component: MainMenu,
    props: true
  },
  {
    path: '/invalidbadge/:token',
    name: 'InvalidBadge',
    component: InvalidBadge,
    props: true
  },
  {
    path: '/lunch/:token',
    name: 'Lunch',
    component: Lunch,
    props: true
  },
  {
    path: '/pos/:token',
    name: 'Pos',
    component: Pos,
    props: true
  },
  {
    path: '/cardinfo/:token',
    name: 'CardInfo',
    component: CardInfo,
    props: true
  },
  {
    path: '/setpin/:token',
    name: 'SetPin',
    component: SetPin,
    props: true
  },
  {
    path: '/user/checkpin/:token',
    name: 'CheckPin',
    component: CheckPin,
    props: true
  },
  {
    path: '/error/:token',
    name: 'Error',
    component: Error,
    props: true
  },
]

const router = new VueRouter({
  //mode: 'history', history mode is not easy to implement on the server but the url is not seen anyway.
  base: process.env.BASE_URL,
  routes
})

export default router
