<template>
  <div class="checkpin">
    <br>
    <b-row>
      <b-col cols="3">
      </b-col>
      <b-col cols="6" class="text-center">
        <br>
        <h3>{{ $t('checkpin.title') }}</h3>
        <br>
        <b-form-input v-model="pin" type="password"></b-form-input>
        <br>
        <b-button-group size="lg" class="pinbuttongroups">
          <b-button squared class="pinbutton" variant="outline-primary" v-on:click="addchar('1')">{{ $t('checkpin.1') }}</b-button>
          <b-button squared class="pinbutton" variant="outline-primary" v-on:click="addchar('2')">{{ $t('checkpin.2') }}</b-button>
          <b-button squared class="pinbutton" variant="outline-primary" v-on:click="addchar('3')">{{ $t('checkpin.3') }}</b-button>
        </b-button-group>
        <br>
        <b-button-group size="lg" class="pinbuttongroups">
          <b-button squared class="pinbutton" variant="outline-primary" v-on:click="addchar('4')">{{ $t('checkpin.4') }}</b-button>
          <b-button squared class="pinbutton" variant="outline-primary" v-on:click="addchar('5')">{{ $t('checkpin.5') }}</b-button>
          <b-button squared class="pinbutton" variant="outline-primary" v-on:click="addchar('6')">{{ $t('checkpin.6') }}</b-button>
        </b-button-group>
        <br>
        <b-button-group size="lg" class="pinbuttongroups">
          <b-button squared class="pinbutton" variant="outline-primary" v-on:click="addchar('7')">{{ $t('checkpin.7') }}</b-button>
          <b-button squared class="pinbutton" variant="outline-primary" v-on:click="addchar('8')">{{ $t('checkpin.8') }}</b-button>
          <b-button squared class="pinbutton" variant="outline-primary" v-on:click="addchar('9')">{{ $t('checkpin.9') }}</b-button>
        </b-button-group>
        <br>
        <b-button-group size="lg" class="pinbuttongroups">
          <b-button squared class="pinbutton" variant="danger" v-on:click="backspace()" :disabled="pin == ''"><font-awesome-icon icon="backspace" class="fa-lg" /></b-button>
          <b-button squared class="pinbutton" variant="outline-primary" v-on:click="addchar('0')">{{ $t('checkpin.0') }}</b-button>
          <b-button squared class="pinbutton" variant="primary" v-on:click="submitpin()"><font-awesome-icon icon="sign-in" class="fa-lg" /></b-button>
        </b-button-group>
      </b-col>
      <b-col cols="4">
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { postData } from '@/api'

  export default {
    name: 'CheckPin',
    data() {
      return {
        pin: '',
        error: ''
      }
    },
    methods: {
      backspace: function() {
        this.pin = this.pin.slice(0, -1)
      },
      addchar: function(char) {
        this.pin += char
      },
      submitpin: function() {
        postData(this.$store.state.settings.platfromUrl + 'api/kiosk/verifystudentpin.php', this.$store.state.settings.apikey, {id: this.$store.state.user.id, pin: this.pin}, (err) => {
          this.loading = false
          if (err) {
            this.error = err.toString()
            this.$root.$bvToast.toast(this.$t('checkpin.error'), {
              title: this.$t('checkpin.errortitle'),
              autoHideDelay: 2500,
              variant: "danger"
            })
            this.pin = ''
          } else {
            // show success toast
            if (this.$route.name != 'MainMenu') {
              this.$router.push({ name: 'MainMenu' })
            }
          }
        })
      }
    }
  }
</script>

<style>
.pinbuttongroups {
  width: 100%;
}
.pinbutton {
  width: 100%;
}
</style>
