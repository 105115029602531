<template>
  <div class="logout">
    <br>
    <h1 class="text-center">{{ $t('user.logout.title') }}</h1>
  </div>
</template>

<script>
  export default {
    name: 'Logout',
    created () {
      this.onLoad()
    },
    methods: {
      onLoad () {
        this.$store.commit('setuserloggedout')
        this.$router.push({ name: 'Login' })
      }
    }
  }
</script>
