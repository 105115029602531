<template>
  <div class="mainmenu">
    <b-alert variant="info" class="mt-3 text-center" :show="$store.state.user.hasordered">{{ $t('mainmenu.userhasordered') }}</b-alert>
    <br>
    <b-row>
      <b-col cols="1">
      </b-col>
      <b-col cols="10">
        <b-row>
          <b-col cols="4" v-if="$store.state.user.profileimage">
            <img v-bind:src="'data:image/jpeg;base64,' + $store.state.user.profileimage" class="userimage"/>
          </b-col>
          <b-col cols="4" v-else></b-col>
          <b-col cols="8">
            <h4>{{ $store.state.user.firstName + ' ' + $store.state.user.lastName }}</h4>
            <h1>{{ '€ ' + $store.state.user.credit.toFixed(2) }}</h1>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1">
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col>
        <b-button block variant="primary" size="lg" :to="{ name: 'Lunch', params: { token: $store.state.token } }"><font-awesome-icon icon="utensils" class="fa-lg" /> {{ $t('mainmenu.buttons.lunch') }}</b-button>
        <br>
        <div v-if="!$store.state.user.teacher">
          <b-button block variant="primary" size="lg" :to="{ name: 'SetPin', params: { token: $store.state.token } }"><font-awesome-icon icon="shield-check" class="fa-lg" /> {{ $t('mainmenu.buttons.changepin') }}</b-button>
          <br>
        </div>
        <div v-if="$store.state.user.teacher">
          <b-button block variant="primary" size="lg" :to="{ name: 'Pos', params: { token: $store.state.token } }">{{ $t('mainmenu.buttons.pos') }}</b-button>
          <br>
        </div>
        <div v-if="$store.state.user.teacher">
          <b-button block variant="primary" size="lg" :to="{ name: 'CardInfo', params: { token: $store.state.token } }">{{ $t('mainmenu.buttons.cardinfo') }}</b-button>
          <br>
        </div>
        <div v-if="$store.state.user.teacher">
          <b-button block variant="primary" size="lg" href=".">{{ $t('mainmenu.buttons.reloadkiosk') }}</b-button>
          <br>
        </div>
        <div v-if="$store.state.user.teacher">
          <b-button block variant="danger" size="lg" v-on:click="enableTeachmode()">{{ $t('mainmenu.buttons.teachmode') }}</b-button>
          <br>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    name: 'MainMenu',
    methods: {
      enableTeachmode: function() {
        // run store teachmode action, this does all the work
        this.$store.commit('setteachmode')
      }
    }
  }
</script>

<style>

.userimage {
  width: 100%;
}

</style>
