<template>
  <div id="app">
    <Navbar />
    <b-container fluid>
      <b-alert variant="danger" class="mt-3 text-center" :show="$store.state.teachmode">{{ $t('teachmodealert') }}</b-alert>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'

export default {
  components: {
    Navbar
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
