<template>
  <div class="invalidbadge">
    <b-row>
      <b-col>
        <br>
        <b-alert variant="danger" class="mt-3 text-center" show>{{ $t('invalidbadge.alert') }}</b-alert>
        <br>
        <b-button block variant="primary" size="lg" :to="{ name: 'Login', params: { token: $store.state.token } }">{{ $t('invalidbadge.loginbuton') }}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    name: 'InvalidBadge',
    methods: {
    }
  }
</script>

<style>
</style>
