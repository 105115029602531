<template>
  <div class="startup">
    <br>
    <div class="text-center text-secondary">
      <font-awesome-icon icon="spinner" class="fa-10x fa-spin" />
    </div>
    <h1 class="text-center">{{ $t('startup.title') }}</h1>
  </div>
</template>

<script>
  export default {
    name: 'Startup'
  }
</script>
