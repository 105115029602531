import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import $ from "jquery";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserTag, faShoppingCart, faCogs, faUnlock, faSignIn, faSpinner, faSave, faTimes, faCheck, faUtensils, faShieldCheck, faCartPlus, faBackspace, faPen, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { BootstrapVue } from 'bootstrap-vue'

Vue.use(BootstrapVue)

import './app.scss'

library.add( faUserTag, faShoppingCart, faCogs, faUnlock, faSignIn, faSpinner, faSave, faTimes, faCheck, faUtensils, faShieldCheck, faCartPlus, faBackspace, faPen, faTrash )

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: "https://7303e363f0f64a97a40f8fca447c721c@o1113613.ingest.sentry.io/4504191899926528",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "cloudkiosk.checkda.be", /^\//],
    }),
  ],
  logErrors: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1,
  release: "chackda-cloudkiosk@" + process.env.VUE_APP_VERSION,
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

// getting the token out of the url to use for fetching the settings

var tempurlparts = window.location.href.split("/");
var settingstoken = tempurlparts[tempurlparts.length - 1];

store.commit('settoken', settingstoken)

// be sure that the startup page is being displayed

if (router.currentRoute.name != 'Startup') {

  router.push({ name: 'Startup', params: { token: store.state.token } })

}

// get settings from api, this is synchronus because everything else depends on it.

$.ajax({
  url: 'https://cloudkioskapi.checkda.be/platform.php?kioskkey=' + settingstoken,
  type: 'get',
  dataType: 'JSON',
  async: false,
  success: function(response){
    if (response.success == true) {
      store.commit('setsettings', response.data)
    } else {
      router.push({ name: 'Error', params: { token: store.state.token } })

      //cb(new Error('Comms error.'))

    }
  },
  error: function() {
    //cb(new Error('Comms error.'))
  }
});

// start websocket

var socket = new WebSocket('ws://' + store.state.settings.badgesystemhost + '/ws');

socket.onopen = function(){

  console.log("connected to websocket");

};

socket.onmessage = function (message) {

  console.log("websocket: " + message.data);

  if (message.data.startsWith("S")) {

    store.commit('setcard', {code: message.data, timestamp: Date.now()})

    if (router.history.current.name == 'Login') {

      // do student login api call

      $.ajax({

        url: store.state.settings.platfromUrl + 'api/kiosk/studentinfo.php',
        headers: {'Apikey': store.state.settings.apikey},
        type: 'post',
        data: JSON.stringify({id: message.data}),
        dataType: 'JSON',
        success: function(response){

          if (response.success == true) {

            store.commit('setuser', {
              id: message.data,
              firstName: response.student.firstName,
              lastName: response.student.lastName,
              profileimage: response.student.profileimage,
              credit: parseFloat(response.student.credit),
              teacher: false,
              administrator: false,
              hasordered: response.student.hasordered,
              pin: response.student.pin
            })

            if (response.student.pin == true) {

              if (router.currentRoute.name != 'CheckPin') {

                router.push({ name: 'CheckPin' })

              }

            } else {

              if (router.currentRoute.name != 'MainMenu') {

                router.push({ name: 'MainMenu' })

              }

            }

          } else if (response.error == "invalidbadge") {

            router.push({ name: 'InvalidBadge' })

          }

        },
        //error: function(XMLHttpRequest, textStatus, errorThrown) {

          //noInternet();

        //},
        timeout: 1200

      });

    }

  } else if (message.data.startsWith("T")) {

    store.commit('setcard', {code: message.data, timestamp: Date.now()})

    if (router.history.current.name == 'Login') {

      // do teacher login api call

      $.ajax({

        url: store.state.settings.platfromUrl + 'api/kiosk/teacherinfo.php',
        headers: {'Apikey': store.state.settings.apikey},
        type: 'post',
        data: JSON.stringify({id: message.data}),
        dataType: 'JSON',
        success: function(response){

          if (response.teacher!=undefined) {

            store.commit('setuser', {
              id: message.data,
              firstName: response.teacher.firstName,
              lastName: response.teacher.lastName,
              administrator: response.teacher.administrator,
              profileimage: false,
              credit: response.teacher.credit,
              teacher: true,
              pin: false
            })

            if (router.currentRoute.name != 'MainMenu') {

              router.push({ name: 'MainMenu', params: { token: store.state.token } })

            }

          }

        },
        //error: function(XMLHttpRequest, textStatus, errorThrown) {

          //noInternet();

        //},
        timeout: 1200

      });

    }

  }

  if (router.history.current.name == 'CardInfo') {

    $.ajax({

      url: store.state.settings.platfromUrl + 'api/kiosk/cardinfo.php',
      headers: {'Apikey': store.state.settings.apikey},
      type: 'post',
      data: JSON.stringify({id: message.data}),
      dataType: 'JSON',
      success: function(response){

        if (response.success == true) {

          console.log(response.cardinfo);

          store.commit('setcardinfo', {
            firstName: response.cardinfo.firstName,
            lastName: response.cardinfo.lastName,
            timestamp: Date.now(),
            userType: response.cardinfo.userType
          })

        }

      },
      //error: function(XMLHttpRequest, textStatus, errorThrown) {

        //noInternet();

      //},
      timeout: 1200

    });

  }

};

socket.onclose = function(){
  store.commit('setwebsocketstate', false)
  router.push({ name: 'Error', params: { token: store.state.token } })
};

// get data from cloud serivce

$.ajax({
  url: store.state.settings.platfromUrl + 'api/kiosk/products.php',
  headers: {'Apikey': store.state.settings.apikey},
  type: 'get',
  dataType: 'JSON',
  success: function(response){
    store.commit('setproducts', response.products)
  },
  error: function() {
    router.push({ name: 'Error', params: { token: store.state.token } })
  },
  timeout: 3000
});

// auto logoff suff here:

var inactivityTime = function () {

  var time;
  var time2;

  window.onload = resetTimer;
  // DOM Events
  document.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onmousedown = resetTimer;
  document.ontouchstart = resetTimer;
  document.onclick = resetTimer;
  document.onkeypress = resetTimer;
  document.addEventListener('scroll', resetTimer, true);

  function checklogout() {

    if (router.history.current.name != 'Error') {

      router.push({ name: 'Logout', params: { token: store.state.token } })

    }

  }

  function resetTimer() {

    clearTimeout(time);
    clearTimeout(time2);
    time = setTimeout(checklogout, 120000);

  }

};

inactivityTime();

// start app now

if (store.state.settings && Object.keys(store.state.settings).length === 0 && Object.getPrototypeOf(store.state.settings) === Object.prototype) {

  router.push({ name: 'Error', params: { token: store.state.token } })

} else {

  router.push({ name: 'Login', params: { token: store.state.token } })

}
