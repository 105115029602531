<template>
  <div>
    <div v-if="$store.state.user.id && route != 'Error'">
      <b-navbar toggleable="lg" type="dark" variant="primary">
        <img v-if="Object.keys($store.state.user).length === 0" src="@/assets/nl/logo.svg" class="logo">
        <b-navbar-brand v-else :to="{ name: 'MainMenu', params: { token: $store.state.token } }">
          <img src="@/assets/nl/logo.svg" class="logo">
        </b-navbar-brand>
        <b-navbar-nav class="ml-auto">
          <b-nav-item :to="{ name: 'Logout', params: { token: $store.state.token } }">{{ $t('navbar.logoff', {name: $store.state.user.firstName }) }}</b-nav-item>
        </b-navbar-nav>
      </b-navbar>
    </div>
    <div v-else>
      <b-navbar toggleable="lg" type="dark" variant="primary">
        <b-navbar-brand>
          <img src="@/assets/nl/logo.svg" class="logo">
        </b-navbar-brand>
        <b-navbar-nav class="ml-auto">
          <b-nav-item> &nbsp; </b-nav-item>
        </b-navbar-nav>
      </b-navbar>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Navbar',
    data() {
      return {
        route: ''
      }
    },
    watch: {
      $route (to){
        this.route = to.name;
      }
    }
  }
</script>

<style scoped>
.logo {
  max-height: 38.48px;
}
</style>

<style>
.nav-item.nav-item.nav-item a {
  color: #132351;
}
</style>
